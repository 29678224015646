export default [
	{
        title: 'Date',
        key: 'date',
		sortable: true,
        minWidth: 80,
    },

    {
        title: 'Shift',
        key: 'Value',
        minWidth: 80,
		sortable: false,
    },

    {
        title: 'Time (In)',
        key: 'Value',
        minWidth: 80,
		sortable: false,
    },

    {
        title: 'Time (Out)',
        key: 'Value',
        minWidth: 80,
		sortable: false,
    },

    {
        title: 'No. Of Hours',
        key: 'Value',
        minWidth: 80,
		sortable: false,
    },

    {
        title: 'Notes',
        key: 'notes',
        minWidth: 80,
		sortable: false,
    }
]