<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="User Monthly Attendance Details" slot="title" link="/helpContent/User Detail" />
        <br />
        <DataTable :actions="true"
            :data="detailsData"
            :columns="tableConfig"
            :is-loading="isLoading">
        </DataTable>
        <br />
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DataTable from 'Components/DataTable';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getDetails} from '../api';
    import tableConfig from './table';
    export default {
        name: "Detail",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            DataTable,
            PageTitle
        },
        data() {
            return {
                tableConfig,
                detailsData: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const data = {
                        userId: this.$route.params.id?atob(this.$route.params.id):0
                    };
                    getDetails(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.detailsData = response.data;
            },
        }
    }
</script>